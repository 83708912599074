export default {
	viewOverTime: () => {
		return {
			title: "View over time",
			option: {
				chartType: "bar",
				xAxis: {
					data: [],
				},
				yAxis: {
					axisLabel: {
						formatter: "{value}",
						// formatter: "{value}K",
					},
				},
				series: [],
			},
		};
	},
	duration: () => {
		return {
			title: "Duration",
			option: {
				chartType: "line",
				xAxis: {
					data: [],
				},
				yAxis: {
					axisLabel: {
						formatter: "{value}S",
					},
				},
				series: [],
			},
		};
	},
	pageByView: (pagesData) => {
		const data = pagesData;
		const sum = data.reduce((previous, current) => previous + current.value, 0);
		return {
			title: "Page by views",
			option: {
				chartType: "pie",
				tooltip: {
					trigger: "item",
				},
				legend: {
					type: 'scroll',
					bottom: '0%',
					left: 'center',
					itemGap: 10,
					formatter: function (name) {
						const value = data.find((item) => item.name === name).value;
						return `${name} \t ${(value / 1000).toFixed(2)}K / ${(
							(value / sum) *
							100
						).toFixed(2)}% \t\t`;
					},
				},
				series: [
					{
						type: "pie",
						radius: ["40%", "60%"],
						label: {
							color: "#000",
							fontSize: "25",
							position: "center",
							formatter: () => {
								return `Total\n${(sum / 1000).toFixed(2)}K`;
							},
						},
						data: data,
					},
				],
			},
		};
	},

	devices: (devicesData) => {
		const data = devicesData;
		const sum = data.reduce((previous, current) => previous + current.value, 0);
		return {
			title: "Devices",
			option: {
				chartType: "pie",
				tooltip: {
					trigger: "item",
				},
				legend: {
					type: 'scroll',
					bottom: '0%',
					left: 'center',
					itemGap: 10,
					formatter: function (name) {
						const value = data.find((item) => item.name === name).value;
						return `${name} \t ${(value / 1000).toFixed(2)}K / ${(
							(value / sum) *
							100
						).toFixed(2)}% \t\t`;
					},
				},
				series: [
					{
						type: "pie",
						radius: ["40%", "60%"],
						avoidLabelOverlap: false,
						label: {
							color: "#000",
							fontSize: "25",
							position: "center",
							formatter: () => {
								return `Total\n${(sum / 1000).toFixed(2)}K`;
							},
						},
						data,
					},
				],
			},
		};
	},

	browsers: (browsersData) => {
		const data = browsersData;
		const sum = data.reduce((previous, current) => previous + current.value, 0);
		return {
			title: "Browsers",
			option: {
				chartType: "pie",
				tooltip: {
					trigger: "item",
				},
				legend: {
					type: 'scroll',
					bottom: '0%',
					left: 'center',
					itemGap: 10,
					formatter: function (name) {
						const value = data.find((item) => item.name === name).value;
						return `${name} \t ${(value / 1000).toFixed(2)}K / ${(
							(value / sum) *
							100
						).toFixed(2)}% \t\t`;
					},
				},
				series: [
					{
						type: "pie",
						radius: ["40%", "60%"],
						avoidLabelOverlap: false,
						label: {
							color: "#000",
							fontSize: "25",
							position: "center",
							formatter: () => {
								return `Total\n${(sum / 1000).toFixed(2)}K`;
							},
						},
						data,
					},
				],
			},
		};
	},
};
